import gql from 'graphql-tag';

export const GET_ADMINISTRADOR_LOGADO = gql`
  query administradorLogado {
    administrador: administradorLogado {
      id
      nome
      email
      usuario {
        authorities {
          nome
        }
      }
    }
  }
`;

export const TOTALIZADORES_ANUNCIOS = gql`
  query GetTotaisAnuncios {
    totalAnunciosFinalizadosDoComercial
    totalAnunciosAtivosDoComercial
    totalAnunciosPendentesDoComercial
  }
`;

export const FIND_ALL_CIDADES = gql`
  query findAllCidade($pageable: PageableDTOInput, $searchDTO: SearchDTOInput) {
    cidades: findAllCidade(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        id
        nome
        nomeEUf
        uf
        kmPadrao
        kmMedio
        latitude
        longitude
        estado {
          id
          nome
          uf
          kmPadrao
        }
      }
    }
  }
`;

export const FIND_ALL_MODELO_ANUNCIO = gql`
  query findAllModeloAnuncio(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    findAllModeloAnuncio(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        id
        nome
        descricao
        valorVenda
        valorMotorista
        valorInstaladorPorInstalacao
        diasPeriodo
        ativo
        tabelaEspecial
        tabelaUm
        tabelaDois
        tabelaTres
      }
    }
  }
`;

export const PRIMEIRO_ANUNCIO_DA_CAMPANHA = gql`
  query PrimeiroAnuncioDaCampanha($campanha: CampanhaInput) {
    primeiroAnuncioDaCampanha(campanha: $campanha) {
      imagensInstalacao {
        imagemOdometro
        imagemDiagonalDireitaFrontal
        imagemDiagonalEsquerdaTraseira
        imagemLateralEsquerda
        imagemLateralDireita
        imagemFrente
        imagemTraseira
      }
    }
  }
`;

export const FIND_ALL_CAMPANHAS_MONITORAR = gql`
  query findAllCampanhasMonitoramento(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    campanhas: findAllCampanhasMonitoramento(
      pageable: $pageable
      searchDTO: $searchDTO
    ) {
      last
      totalElements
      content {
        id
        quantidadeKmMotoristaMes
        kmTotal
        quantidadeVeiculos
        totalKmRodado
        totalKmRodadoMes
        modeloAnuncio {
          nome
        }
        campanha {
          id
          numero
          titulo
          situacao
          quantidadeMeses
          dataCompra
          dataInicioVeiculacao
          diasPeriodo
          anunciante {
            id
            nomeRazao
            nomeFantasia
          }
          comercial {
            nomeRazao
            nomeFantasia
            endereco {
              cidade {
                nome
                uf
                latitude
                longitude
              }
            }
          }
        }
        cidade {
          nome
          uf
          nomeEUf
        }
        anuncios {
          id
          kmRodado
          kmTotal
          dataInstalacao
          dataInicioVeiculacao
          quantidadeMeses
          kmTotalPorMes
          kmRodadoMes
          observacaoMonitoramento
          motorista {
            nome
            celular
          }
          veiculo {
            modelo {
              nome
              marca {
                nome
              }
            }
          }
        }
      }
    }
  }
`;

export const FIND_ALL_CAMPANHA = gql`
  query findAllCampanha(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    findAllCampanha(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        id
        numero
        titulo
        situacao
        quantidadeVeiculos
        quantidadeVeiculosNaCampanha
        quantidadeMeses
        dataCompra
        dataInicioVeiculacao
        diasPeriodo
        anunciante {
          id
          nomeRazao
          nomeFantasia
        }
        comercial {
          nomeRazao
          nomeFantasia
          nomeRazaoResponsavel
          endereco {
            cidade {
              nome
              uf
              latitude
              longitude
            }
          }
        }
        cidades {
          modeloAnuncio {
            nome
          }
          cidade {
            nome
            uf
            latitude
            longitude
          }
        }
      }
    }
  }
`;

export const FIND_ALL_CAMPANHAS_ANUNCIOS = gql`
  query findAllCampanha(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    findAllCampanha(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        id
        titulo
        quantidadeVeiculos
        modeloAnuncio {
          nome
        }
        anunciante {
          id
          nomeRazao
          nomeFantasia
        }
        cidade {
          nome
          uf
        }
        anuncios {
          situacao
        }
      }
    }
  }
`;

export const FIND_ALL_MOTORISTA = gql`
  query findAllMotorista(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    findAllMotorista(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        id
        nome
        cpf
        aprovado
        anuncioAtivo {
          id
          modeloAnuncio {
            interno
            laterais
            traseira
            frente
          }
        }
        cadastroCompleto
        dataCadastro
        dataAlteracao
        celular
        celularAdicional
        situacao
        notaAvaliacao
        endereco {
          cidade {
            id
            nome
            uf
            nomeEUf
            latitude
            longitude
          }
        }
        veiculoAtual {
          tipoVeiculo
          placa
          modelo {
            nome
            marca {
              nome
            }
          }
        }
      }
    }
  }
`;

export const FIND_ALL_AVALIACAO_MOTORISTA = gql`
  query findAllAvaliacaoMotorista($pageable: PageableDTOInput) {
    findAllAvaliacaoMotorista(pageableDto: $pageable) {
      last
      totalElements
      content {
        id
        nota
        observacao
        anuncio {
          campanhaTitulo
        }
        motorista {
          nome
          veiculoAtual {
            modelo {
              nome
            }
          }
        }
      }
    }
  }
`;

export const FIND_BY_DISPONIBILIDADE_MOTORISTA = gql`
  query findByDisponibilidadeMotorista(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    motoristas: findByDisponibilidadeMotorista(
      pageable: $pageable
      searchDTO: $searchDTO
    ) {
      last
      totalElements
      content {
        id
        nome
        notaAvaliacao
        situacao
        veiculoAtual {
          anoFabricacao
          anoModelo
          placa
          pinturaOriginal
          modelo {
            nome
            marca {
              nome
            }
          }
        }
        cidadeAtuacao {
          codigoIBGE
          id
          nome
          uf
          nomeEUf
        }
      }
    }
  }
`;

export const FIND_ANUNCIOS_DA_CAMPANHA = gql`
  query findAllAnunciosByCampanha($campanha: CampanhaInput) {
    anuncios: findAllAnunciosByCampanha(campanha: $campanha) {
      id
      motorista {
        id
        nome
        anuncioAtivo {
          id
        }
        veiculoAtual {
          anoFabricacao
          anoModelo
          placa
          pinturaOriginal
          modelo {
            nome
            marca {
              nome
            }
          }
        }
        cidadeAtuacao {
          codigoIBGE
          id
          nome
          uf
          nomeEUf
        }
      }
    }
  }
`;

export const FIND_ANUNCIOS_DA_CAMPANHA_CIDADE = gql`
  query findAllAnunciosByCampanhaCidade($campanhaCidade: CampanhaCidadeInput) {
    anuncios: findAllAnunciosByCampanhaCidade(campanhaCidade: $campanhaCidade) {
      id
      motorista {
        id
        nome
        anuncioAtivo {
          id
        }
        veiculoAtual {
          anoFabricacao
          anoModelo
          placa
          pinturaOriginal
          modelo {
            nome
            marca {
              nome
            }
          }
        }
        cidadeAtuacao {
          codigoIBGE
          id
          nome
          uf
          nomeEUf
        }
      }
    }
  }
`;

export const COUNT_ALL_VEICULOS_DA_CAMPANHA = gql`
  query countAllAnunciosByCampanha($campanha: CampanhaInput) {
    count: countAllAnunciosByCampanha(campanha: $campanha)
  }
`;

export const FIND_ALL_ANUNCIOS = gql`
  query findAllAnuncios(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    anuncios: findAllAnuncios(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        id
        situacao
        dataInstalacao
        dataInicioVeiculacao
        dataAgendamentoInstalacao
        quantidadeMeses
        campanhaTitulo
        kmTotalPorMes
        kmTotal
        kmRodado
        campanha {
          titulo
          diasPeriodo
          cidade {
            id
            nome
            uf
            nomeEUf
          }
        }
        avaliacaoMotorista {
          id
          nota
          notaKmRodado
          notaPessoal
          pinturaAvariada
        }
        instalador {
          id
          nome
          value: id
          label: nome
        }
        motorista {
          id
          nome
          celular
          celularAdicional
          dataCadastro
          cpf
          email
          dadoBancario {
            agencia
            banco {
              codigoBanco
              nomeBanco
            }
            conta
            chavePix
            cpfCnpjFavorecido
            nomeFavorecido
            tipoContaBancaria
          }
        }
        veiculo {
          anoFabricacao
          anoModelo
          modelo {
            nome
            marca {
              nome
            }
          }
        }
      }
    }
  }
`;

export const FIND_ALL_ANUNCIOS_CAMPANHA_CIDADE = gql`
  query findAllAnunciosCampanhaCidade(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    anuncios: findAllAnunciosCampanhaCidade(
      pageable: $pageable
      searchDTO: $searchDTO
    ) {
      last
      totalElements
      content {
        id
        situacao
        dataInstalacao
        dataInicioVeiculacao
        dataAgendamentoInstalacao
        quantidadeMeses
        statusApp
        kmTotal
        kmRodado
        campanha {
          titulo
          diasPeriodo
          cidade {
            id
            nome
            uf
            nomeEUf
          }
        }
        avaliacaoMotorista {
          id
        }
        motorista {
          id
          nome
          cpf
          celular
          email
          dataCadastro
          dadoBancario {
            agencia
            conta
            tipoContaBancaria
            nomeFavorecido
            cpfCnpjFavorecido
            chavePix
            banco {
              codigoBanco
              nomeBanco
            }
          }
        }
        instalador {
          id
          nome
          value: id
          label: nome
        }
        veiculo {
          anoFabricacao
          anoModelo
          modelo {
            nome
            marca {
              nome
            }
          }
        }
      }
    }
  }
`;

export const FIND_MOTORISTAS_BY_CIDADE_ESTADO_DISPONIBILIDADE = gql`
  query findMotoristasByCidadeEstadoDisponibilidade(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
    $motoristaDisponivel: Boolean
  ) {
    findByCidadeEstadoDisponibilidade(
      pageable: $pageable
      searchDTO: $searchDTO
      motoristaDisponivel: $motoristaDisponivel
    ) {
      last
      totalElements
      content {
        id
        nome
        aprovado
      }
    }
  }
`;

export const FIND_ALL_EMAIL_CONTATO = gql`
  query findAllEmailContato(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    findAllEmailContato(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        id
        titulo
        tipoDuvida
        email
        telefone
        mensagem
      }
    }
  }
`;

export const FIND_ALL_ANUNCIANTE = gql`
  query findAllAnunciante(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    findAllAnunciante(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        id
        nomeRazao
        nomeFantasia
        cnpj
        cadastroCompleto
        tipo
        dataCadastro
        comercial {
          nomeRazao
          nomeFantasia
        }
        agencia {
          nomeRazao
          nomeFantasia
        }
        executivo {
          nome
        }
        segmentoAtuacao {
          id
          descricao
        }
        endereco {
          cidade {
            id
            nome
            latitude
            longitude
          }
        }
      }
    }
  }
`;
export const CIDADES_CAMPANHAS_ANUNCIANTE = gql`
  query cidadesCampanhasAnunciante($anunciante: AnuncianteInput) {
    cidadesCampanhasAnunciante(anunciante: $anunciante) {
      nome
      id
      uf
      campanhas
    }
  }
`;
export const MODELO_ANUNCIO_CAMPANHAS_ANUNCIANTE = gql`
  query modeloAnuncioCampanhasAnunciante($anunciante: AnuncianteInput) {
    modeloAnuncioCampanhasAnunciante(anunciante: $anunciante)
  }
`;
export const CAMPANHAS_ANUNCIANTE_POR_MES_DO_ANO = gql`
  query campanhasAnunciantePorMesDoAno($anunciante: AnuncianteInput) {
    campanhasAnunciantePorMesDoAno(anunciante: $anunciante)
  }
`;
export const STATUS_MOTORISTA_APP = gql`
  query StatusMotoristaApp($motoristaId: UUID) {
    statusMotoristaApp(motoristaId: $motoristaId) {
      status
    }
  }
`;

export const FIND_BY_ID_MOTORISTA = gql`
  query findByIdMotorista($id: UUID) {
    findByIdMotorista(id: $id) {
      id
      nome
      cpf
      email
      celular
      celularAdicional
      dataNascimento
      dataCadastro
      cnh
      dataValidadeCnh
      fotoCnh
      fotoComprovanteResidencia
      fotoPerfil
      aprovouFotoCnh
      aprovouFotoComprovanteResidencia
      obsFotoCnh
      obsFotoComprovanteResidencia
      situacao
      notaAvaliacao
      observacao
      reprovadoMotivo
      indicacoesPagas
      dataExclusao
      anuncioAtivo {
        id
        campanha {
          valorMensalMotorista
        }
      }
      anuncios {
        id
        campanha {
          valorMensalMotorista
        }
      }
      usuario {
        id
      }
      cidadeAtuacao {
        id
        nome
        uf
        nomeEUf
      }
      veiculoAtual {
        id
        anoModelo
        anoFabricacao
        cor
        fotoDocumentoVeiculo
        fotoFrente
        fotoLateral
        fotoTraseira
        aprovouFotoDocumentoVeiculo
        aprovouFotoVeiculo
        obsFotoDocumentoVeiculo
        obsFotoVeiculo
        pinturaOriginal
        possuiBorrachao
        tipoVeiculo
        permiteEditarVeiculo
        modelo {
          marca {
            nome
          }
          nome
        }
        moldeVeiculo {
          id
          marca {
            id
            nome
          }
          modelo {
            id
            nome
          }
          anoFabricacao
          anoModelo
          arquivoNome
          arquivo
          ativo
        }
        placa
        renavam
      }
      endereco {
        bairro
        logradouro
        complemento
        cep
        cidade {
          id
          nome
          uf
          nomeEUf
        }
        numero
      }
      dadoBancario {
        agencia
        banco {
          id
          codigoBanco
          nomeBanco
        }
        cidade {
          id
          nome
          uf
        }
        estado {
          id
        }
        conta
        chavePix
        cpfCnpjFavorecido
        nomeFavorecido
        tipoContaBancaria
      }
      aprovado
      modelosAnuncioAceitos {
        id
        nome
      }
      motoristaIndicacao {
        nome
      }
    }
  }
`;

export const FIND_ALTERACOES_BY_ID_MOTORISTA = gql`
  query findAlteracoesByIdMotorista($id: UUID) {
    alteracoes: findAlteracoesByIdMotorista(id: $id) {
      id
      nome_Mod
      email_Mod
      cpf_Mod
      dataNascimento_Mod
      cnh_Mod
      dataValidadeCnh_Mod
      codigoPais_Mod
      celular_Mod
      cep_Mod
      cep
      logradouro_Mod
      logradouro
      numero_Mod
      numero
      bairro_Mod
      bairro
      complemento_Mod
      complemento
      cidadeId_Mod
      cidade {
        id
      }
      estadoId_Mod
      paisId_Mod
      fotoComprovanteResidencia_Mod
      fotoComprovanteResidencia
      endereco_Mod
      dadoBancario_Mod
      sexo_Mod
      fotoPerfil_Mod
      fotoCnh_Mod
      situacao_Mod
      cpfCnpjFavorecido_Mod
      cpfCnpjFavorecido
      nomeFavorecido_Mod
      nomeFavorecido
      tipoContaBancaria_Mod
      tipoContaBancaria
      agencia
      dadoBancarioAgencia_Mod
      conta
      dadoBancarioConta_Mod
      dadoBancarioCidade {
        id
      }
      dadoBancarioCidadeId_Mod
      dadoBancarioEstado {
        id
      }
      dadoBancarioEstadoId_Mod
      dadoBancarioPaisId_Mod
      dadoBancarioBancoId_Mod
      banco {
        id
      }
      dadoBancarioChavePix_Mod
      chavePix
      cidadeAtuacaoId_Mod
      cidadeAtuacao {
        id
      }
      veiculoAtualId_Mod
      veiculoAtual {
        id
        fotoFrente
        fotoLateral
        fotoTraseira
        fotoDocumentoVeiculo
      }
      rev
      revtype
    }
  }
`;

export const FIND_ULTIMAS_LOCALIZACOES = gql`
  query findUltimasLocalizacoes($motorista: MotoristaInput) {
    findUltimasLocalizacoes(motorista: $motorista) {
      id
      latitude
      longitude
    }
  }
`;

export const FIND_ALL_COMERCIAL = gql`
  query findAllComercial(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    findAllComercial(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        id
        nomeRazao
        nomeFantasia
        cpfCnpj
        ativo
        role
        endereco {
          cidade {
            id
            nome
            uf
            latitude
            longitude
          }
        }
        estadosAtuacao {
          id
          uf
        }
        licencaCidades {
          id
          cidade {
            id
            nome
            nomeEUf
            uf
            latitude
            longitude
          }
        }
      }
    }
  }
`;

export const COMERCIAL_VENDAS_METRICAS = gql`
  query comercialVendasMetricas(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    comercialVendasMetricas(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      contentComercialVendasMetricas: content {
        id
        campanhas
        veiculosCampanha
        faturamento
        mediaVeiculo
        mediaVenda
        metaVenda
        nomeFantasia
        propostas
        resultado
        veiculos
      }
    }
  }
`;

export const TOTAL_COMERCIAL_VENDAS_METRICAS = gql`
  query totalComercialVendasMetricas($searchDTO: SearchDTOInput) {
    total: totalComercialVendasMetricas(searchDTO: $searchDTO) {
      campanhas
      veiculosCampanha
      faturamento
      mediaVeiculo
      mediaVenda
      metaVenda
      propostas
      resultado
      veiculos
    }
  }
`;

export const FIND_ALL_MOVIMENTACOES_FINANCEIRAS = gql`
  query findAllMovimentacoesFinanceiras(
    $searchDTO: SearchMovimentacoesDTOInput
    $pageable: PageableDTOInput
  ) {
    movimentacoes: findAllMovimentacoesFinanceiras(
      pageable: $pageable
      searchDTO: $searchDTO
    ) {
      last
      totalElements
      content {
        id
        dataInicioPeriodo
        dataFimPeriodo
        dataPgto
        dataLcto
        dataAprovacao
        dataPrevisaoPgto
        valor
        valorPago
        valorReferencia
        origemMovimentacaoFinanceira
        tipo
        movimentacaoOrigem
        parcelaTipo
        situacao
        anuncio {
          motorista {
            nome
          }
        }
        motorista {
          nome
          dadoBancario {
            chavePix
          }
        }
        instalador {
          nome
        }
        comercial {
          nomeRazao
          nomeFantasia
          role
        }
        licencaRecebimento {
          nomeRazao
          nomeFantasia
          role
        }
        anunciante {
          nomeRazao
          nomeFantasia
        }
        campanha {
          numero
          titulo
          valorVeiculacaoLiquido
          parcelasVeiculacao
          repasseTerceiros
          repasseAgencia
          quantidadeMeses
          valorVeiculacao
          anunciante {
            nomeRazao
            nomeFantasia
          }
          comercial {
            role
          }
          cidades {
            modeloAnuncio {
              nome
            }
            cidade {
              id
              nomeEUf
            }
          }
        }
      }
    }
  }
`;

export const FIND_BY_ID_MOVIMENTACAO_FINANCEIRA = gql`
  query findByIdMovimentacaoFinanceira($id: UUID) {
    findByIdMovimentacaoFinanceira(id: $id) {
      id
      dataInicioPeriodo
      dataFimPeriodo
      dataPgto
      dataLcto
      dataPrevisaoPgto
      reciboAssinadoData
      valor
      valorPago
      origemMovimentacaoFinanceira
      tipo
      formaPagamento
      valorBonusDiarioPerido
      valorBonusExtraPerido
      valorBonusKmPerido
      valorBonusMensalPerido
      kmRodadoPeriodo
      quantidadeBonusDiarioPeriodo
      obs
      codigoComprovante
      dataLctoPgto
      movimentacaoOrigem
      situacao
      parcelaTipo
      usuarioBaixa {
        nome
      }
      anuncio {
        motorista {
          nome
        }
        veiculoAnoFabricacao
        veiculoAnoModelo
        veiculoModelo
        veiculoPlaca
      }
      motorista {
        nome
        dadoBancario {
          agencia
          banco {
            codigoBanco
            nomeBanco
          }
          conta
          chavePix
          cpfCnpjFavorecido
          nomeFavorecido
          tipoContaBancaria
        }
      }
      instalador {
        nome
      }
      comercial {
        nomeRazao
        nomeFantasia
        porcentagemValorRepasse
        porcentagemImportacao
        dadoBancario {
          agencia
          banco {
            codigoBanco
            nomeBanco
          }
          conta
          chavePix
          cpfCnpjFavorecido
          nomeFavorecido
          tipoContaBancaria
        }
      }
      licencaRecebimento {
        nomeRazao
        nomeFantasia
        porcentagemValorRepasse
        porcentagemImportacao
        dadoBancario {
          agencia
          banco {
            codigoBanco
            nomeBanco
          }
          conta
          chavePix
          cpfCnpjFavorecido
          nomeFavorecido
          tipoContaBancaria
        }
      }
      anunciante {
        nomeRazao
        cnpj
        comercial {
          nomeRazao
          nomeFantasia
        }
      }
      campanha {
        numero
        titulo
        valorVeiculacao
        repasseAgencia
        repasseTerceiros
        quantidadeMeses
        movimentacaoFinanceiras {
          parcelaTipo
          tipo
        }
        anunciante {
          cnpj
          nomeRazao
          nomeFantasia
        }
        comercial {
          nomeRazao
          nomeFantasia
          nomeRazaoResponsavel
        }
      }
      campanhaCidade {
        modeloAnuncio {
          nome
        }
      }
     documento{ 
      id 
      nome
     }
    }
  }
`;

export const FIND_ALL_ADMINISTRADOR = gql`
  query findAllAdministrador(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    findAllAdministrador(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        id
        nome
        email
        ativo
        usuario {
          authorities {
            nome
          }
        }
      }
    }
  }
`;

export const FIND_ALL_INSTALADOR = gql`
  query findAllInstalador(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    findAllInstalador(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        id
        nome
        nomeFantasia
        cpfCnpj
        ativo
        endereco {
          cidade {
            id
            nome
            uf
          }
        }
      }
    }
  }
`;
export const FIND_ALL_IMAGENS_APROVADAS_ANUNCIO = gql`
  query findAllImagensVeiculoAprovadasByAnuncio(
    $anuncio: AnuncioInput
    $dataInicio: LocalDate
    $dataFim: LocalDate
  ) {
    imagensAnuncio: findAllImagensVeiculoAprovadasByAnuncio(
      anuncio: $anuncio
      dataInicio: $dataInicio
      dataFim: $dataFim
    ) {
      id
      imagemLateralEsquerda
      imagemLateralDireita
      imagemFrente
      imagemTraseira
      imagemInterna
      imagemDiagonalDireitaFrontal
      imagemDiagonalEsquerdaTraseira
      imagemOdometro
      valorOdometro
      latitude
      longitude
      tipo
      data
      motorista {
        id
      }
    }
    kmRodado: quilometroRodadoPeriodoAnuncio(
      anuncio: $anuncio
      dataInicio: $dataInicio
      dataFim: $dataFim
    )
  }
`;

export const FIND_ALL_CAMPANHAS_COM_VEICULOS_FALTANDO = gql`
  query findAllCampanhasComVeiculosFaltando(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    findAllCampanhasComVeiculosFaltando(
      pageable: $pageable
      searchDTO: $searchDTO
    ) {
      last
      totalElements
      content {
        id
        titulo
      }
    }
  }
`;

export const FIND_ALL_ANUNCIOS_SELECT = gql`
  query findAllAnuncios(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    findAllAnuncios(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        id
        campanhaTitulo
        dataInstalacao
        dataInicioVeiculacao
        veiculoModelo
        veiculoPlaca
        motorista {
          nome
          celular
        }
      }
    }
  }
`;

export const FIND_ALL_ANUNCIOS_BY_CAMPANHA_CIDADE = gql`
  query findAllAnunciosByCampanhaCidade($campanhaCidade: CampanhaCidadeInput) {
    anuncios: findAllAnunciosByCampanhaCidade(campanhaCidade: $campanhaCidade) {
      id
      dataInstalacao
      dataInicioVeiculacao
      kmTotalPorMes
      quantidadeMeses
      kmRodado
      kmTotal
      kmRodadoDia
      tempoRodado
      veiculo {
        id
        anoFabricacao
        anoModelo
        placa
        modelo {
          nome
        }
      }
      motorista {
        id
        nome
        fotoPerfil
        veiculoAtual {
          id
          anoModelo
          anoFabricacao
          placa
          modelo {
            nome
            marca {
              nome
            }
          }
        }
      }
      localizacaoAtual {
        latitude
        longitude
        data
      }
      modeloAnuncio {
        frente
        laterais
        traseira
        interno
      }
    }
  }
`;

export const FIND_ANUNCIOS_PARADOS_A_MAIS_DE = gql`
  query findAnunciosParadosAMaisDe(
    $pageable: PageableDTOInput
    $quantidadeDeDias: Int
  ) {
    findAnunciosParadosAMaisDe(
      pageable: $pageable
      quantidadeDeDias: $quantidadeDeDias
    ) {
      last
      totalElements
      content {
        id
        campanha {
          titulo
        }
        motorista {
          nome
          celular
          cidadeAtuacao {
            codigoIBGE
            id
            nome
            uf
            nomeEUf
          }
        }
        veiculoModelo
        kmRodadoMes
        kmTotalPorMes
        veiculoPlaca
        localizacaoAtual {
          data
        }
      }
    }
  }
`;

export const COUNT_ANUNCIOS_PARADOS_A_MAIS_DE = gql`
  query totalAnunciosParadosAMaisDe {
    totalAnunciosParadosAMaisDe
  }
`;

export const FIND_ALL_MOTORISTAS_SUBSTITUIDOS = gql`
  query findAllMotoristasSubtituidos($searchDTO: SearchDTOInput) {
    findAllMotoristasSubtituidos(searchDTO: $searchDTO) {
      id
      motorista {
        nome
      }
      anuncio {
        campanha {
          titulo
          cidade {
            nomeEUf
          }
        }
      }
    }
  }
`;
export const IMAGENS_PENDENTES_DE_VALIDACAO = gql`
  query ImagensPendentesDeValidacao(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    findAllImagens: findAllAnuncioImagemVeiculo(
      pageable: $pageable
      searchDTO: $searchDTO
    ) {
      last
      totalElements
      content {
        id
        tipo
        data
        dataConfirmacao
        aprovado
        situacao
        anuncio {
          id
          campanhaTitulo
          veiculoModelo
          veiculoPlaca
          campanha {
            numero
            titulo
            cidade {
              id
              nome
              uf
            }
          }
          motorista {
            id
            nome
          }
        }
        motorista {
          id
          nome
        }
        veiculo {
          placa
          modelo {
            nome
          }
        }
      }
    }
  }
`;

export const FIND_ALL_ANUNCIOS_IMAGENS_REMOCAO = gql`
  query findAllAnunciosImagensRemocao(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    findAllAnuncios(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        id
        campanhaTitulo
        dataRemocao
        veiculoModelo
        veiculoPlaca
        motorista {
          nome
        }
      }
    }
  }
`;

export const FIND_BY_ID_COMERCIAL = gql`
  query findByIdComercial($id: UUID) {
    findByIdComercial(id: $id) {
      celular
      cpfCnpj
      email
      role
      endereco {
        bairro
        logradouro
        complemento
        cep
        cidade {
          id
          nome
          uf
          nomeEUf
        }
        numero
      }
      dadoBancario {
        agencia
        banco {
          id
          codigoBanco
          nomeBanco
          codigoENome
        }
        cidade {
          id
          nome
          uf
          nomeEUf
        }
        conta
        chavePix
        cpfCnpjFavorecido
        nomeFavorecido
        tipoContaBancaria
      }
      fotoPerfil
      id
      nomeContato
      nomeFantasia
      nomeRazao
      porcentagemValorRepasse
      porcentagemImportacao
      telefone
      ativo
      usuario {
        authorities {
          nome
        }
      }
      arquivos {
        id
        titulo
        dataAlteracao
        arquivo {
          id
          nome
        }
      }
      licencaCidades {
        id
        cidade {
          id
          nomeEUf
          value: id
          label: nomeEUf
        }
        cidadesRegiao {
          id
          nomeEUf
          value: id
          label: nomeEUf
        }
      }
      estadosAtuacao {
        id
        uf
        value: id
        label: uf
      }
      cidadesAtuacao {
        id
        nomeEUf
        value: id
        label: nomeEUf
      }
      comercialResponsavel {
        id
        nomeFantasia
        nomeRazao
        value: id
        label: nomeRazao
      }
    }
  }
`;

export const FIND_BY_ID_ANUNCIANTE = gql`
  query findByIdAnunciante($id: UUID) {
    findByIdAnunciante(id: $id) {
      celular
      cnpj
      inscricaoEstadual
      dataCadastro
      tipo
      segmentoAtuacao {
        id
        descricao
        value: id
        label: descricao
      }
      usuario {
        email
      }
      endereco {
        bairro
        logradouro
        complemento
        cep
        cidade {
          id
          nome
          uf
          nomeEUf
          value: id
          label: nomeEUf
        }
        numero
      }
      fotoPerfil
      id
      nomeContato
      nomeFantasia
      nomeRazao
      site
      marketingNome
      marketingEmail
      marketingTelefone
      financeiroNome
      financeiroEmail
      financeiroTelefone
      telefone
      cadastroCompleto
      painelDemonstracao
      marketingNomeAdicional
      marketingTelefoneAdicional
      marketingEmailAdicional
      financeiroNomeAdicional
      financeiroTelefoneAdicional
      financeiroEmailAdicional
      metaVenda
      dataProgramacaoAnual
      comercial {
        id
        nomeRazao
        nomeFantasia
        value: id
        label: nomeRazao
      }
      agencia {
        id
        nomeRazao
        nomeFantasia
        value: id
        label: nomeFantasia
      }
      executivo {
        id
        nome
        value: id
        label: nome
      }
    }
  }
`;

export const FIND_BY_ID_ADMINISTRADOR = gql`
  query findByIdAdministrador($id: UUID) {
    findByIdAdministrador(id: $id) {
      id
      cpf
      dataNascimento
      nome
      email
      ativo
      endereco {
        bairro
        logradouro
        complemento
        cep
        cidade {
          id
          nome
          uf
        }
        numero
      }
      usuario {
        authorities {
          nome
        }
      }
    }
  }
`;

export const FIND_BY_ID_ANUNCIO_IMAGENS_VALIDACAO = gql`
  query findByIdAnuncioImagensValidacao($id: UUID) {
    findByIdAnuncio(id: $id) {
      id
      campanhaTitulo
      imagensDoVeiculo {
        id
        aprovado
        data
        imagemDiagonalDireitaFrontal
        imagemDiagonalEsquerdaTraseira
        imagemOdometro
        imagemLateralEsquerda
        imagemLateralDireita
        imagemFrente
        imagemTraseira
        tipo
        usuarioConfirmou {
          nome
        }
        anuncio {
          campanhaTitulo
        }
      }
    }
  }
`;

export const FIND_BY_ID_ANUNCIO_IMAGEM_VEICULO = gql`
  query findByIdAnuncioImagemVeiculo($id: UUID) {
    findByIdAnuncioImagemVeiculo(id: $id) {
      id
      aprovado
      data
      dataConfirmacao
      imagemDiagonalDireitaFrontal
      imagemDiagonalEsquerdaTraseira
      imagemOdometro
      imagemLateralEsquerda
      imagemLateralDireita
      imagemFrente
      imagemTraseira
      imagemInterna
      valorOdometro
      latitude
      longitude
      tipo
      situacao
      usuarioConfirmou {
        nome
      }
      anuncio {
        id
        campanhaTitulo
        campanha {
          valorMensalMotorista
        }
        kmTotalPorMes
        modeloAnuncio {
          laterais
          traseira
          interno
          frente
        }
        motorista {
          id
          nome
        }
        veiculo {
          cor
          placa
          modelo {
            nome
            marca {
              nome
            }
          }
        }
      }
      motorista {
        id
        nome
      }
      veiculo {
        cor
        placa
        modelo {
          nome
          marca {
            nome
          }
        }
      }
    }
  }
`;

export const FIND_BY_ID_ANUNCIO_IMAGENS_INSTALACAO = gql`
  query findByIdAnuncioImagensInstalacao($id: UUID) {
    findByIdAnuncio(id: $id) {
      id
      campanhaTitulo
      imagensDoVeiculo {
        id
        aprovado
        data
        imagemDiagonalDireitaFrontal
        imagemDiagonalEsquerdaTraseira
        imagemOdometro
        imagemLateralEsquerda
        imagemLateralDireita
        imagemFrente
        imagemTraseira
        tipo
      }
    }
  }
`;

export const FIND_BY_ID_ANUNCIO_IMAGENS_REMOCAO = gql`
  query findByIdAnuncioImagensRemocao($id: UUID) {
    findByIdAnuncio(id: $id) {
      id
      campanhaTitulo
      imagensDoVeiculo {
        id
        aprovado
        data
        imagemDiagonalDireitaFrontal
        imagemDiagonalEsquerdaTraseira
        imagemOdometro
        imagemLateralEsquerda
        imagemLateralDireita
        imagemFrente
        imagemTraseira
        tipo
      }
    }
  }
`;

export const FIND_BY_ID_INSTALADOR = gql`
  query findByIdInstalador($instalador: InstaladorInput) {
    findByIdInstalador(instalador: $instalador) {
      id
      nome
      fotoPerfil
      nomeContato
      nomeFantasia
      financeiroNome
      financeiroEmail
      financeiroTelefone
      telefone
      fatorAjusteTempoInstalacao
      celular
      cpfCnpj
      email
      ativo
      valorLateralCompleta
      valorLateralRecorte
      valorCapo
      valorVidroTrazeiro
      valorBanner
      valorBonusMotorista
      cpfCnpjFavorecido
      nomeFavorecido
      tipoContaBancaria
      agencia
      conta
      chavePix
      bancoCidade {
        id
        nomeEUf
      }
      banco {
        id
        codigoBanco
        nomeBanco
        codigoENome
      }
      observacao
      endereco {
        bairro
        logradouro
        complemento
        cep
        cidade {
          id
          nome
          uf
          nomeEUf
          value: id
          label: nomeEUf
        }
        numero
      }
    }
  }
`;

export const FIND_BY_ID_CAMPANHA = gql`
  query findByIdCampanha($id: UUID) {
    findByIdCampanha(id: $id) {
      id
      titulo
      numero
      dataInicioVeiculacao
      diasPeriodo
      bannerCampanha
      valorMensalRepasseComercial
      situacao
      faturarPor
      quantidadeMeses
      quantidadeVeiculos
      anunciante {
        id
        nomeRazao
        nomeFantasia
        value: id
        label: nomeFantasia
        comercial {
          id
          nomeFantasia
          nomeRazao
        }
      }
      comercial {
        id
        nomeFantasia
        nomeRazao
        value: id
        label: nomeRazao
        comercialResponsavel {
          id
          nomeRazao
        }
      }
      cidades {
        id
        instalador {
          id
          email
          nomeFantasia
          value: id
          label: nomeFantasia
        }
        modeloAnuncio {
          value: id
          label: nome
          id
          nome
          valorVenda
          valorMotorista
          valorInstaladorPorInstalacao
          diasPeriodo
          tabelaEspecial
          tabelaUm
          tabelaDois
          tabelaTres
        }
        cidade {
          id
          nome
          uf
          nomeEUf
          estado {
            id
            nome
          }
          latitude
          longitude
          value: id
          label: nomeEUf
        }
        quantidadeVeiculos
        quantidadeKmMotoristaMes
        valorPorVeiculo
        desconto
        valorBrutoVeiculo
        instalacaoPorVeiculo
        valorMensalMotorista
        valorMotorista
        raioCidade
      }
      valor
      valorVeiculacaoMensal
      valorVeiculacao
      valorReajuste
      kmTotal
      formaDePagamento
      formaDePagamentoProducao
      repasseAgencia
      repasseTerceiros
      impostos
      faturarProducao
      pedidoInsercao {
        id
        nome
      }
      pedidoInsercaoProducao {
        id
        nome
      }
      observacoesRelatorios
      faturamentoProprioProducao
      movimentacaoFinanceiras {
        id
        valor
        formaPagamento
        dataPrevisaoPgto
        tipo
        parcelaTipo
        dataEmitirNota
        notaEmitida
        anunciante {
          id
        }
      }
      usuario {
        id
        nome
      }
      mensagemReprovacao
    }
  }
`;

export const FIND_BY_ID_MODELO_ANUNCIO = gql`
  query findByIdModeloAnuncio($id: UUID) {
    findByIdModeloAnuncio(id: $id) {
      id
      nome
      descricao
      valorMotorista
      valorBonusInstalacao
      valorMarkup
      valorVenda
      valorMensalBonusDiario
      valorMensalBonusExtra
      valorMensalBonusKmRodado
      valorMensalBonusMensal
      quantidadeKmBonusDiario
      quantidadeKmMes
      quantidadeMaximaDiasBonusDiario
      quantidadeMinimaMeses
      quantidadeMinimaVeiculos
      valorInstaladorPorInstalacao
      valorInstaladorPorRemocao
      valorMensalRepasseKMMoney
      tempoInstalacaoMinutos
      ativo
      frente
      laterais
      lateraisRecorte
      traseira
      interno
      interativo
      percentualImpactos
      diasPeriodo
      tabelaEspecial
      tabelaUm
      tabelaDois
      tabelaTres
    }
  }
`;

export const FIND_ALL_MODELO_VEICULO = gql`
  query findAllModelo($pageable: PageableDTOInput, $searchDTO: SearchDTOInput) {
    modelos: findAllModelo(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        id
        nome
        marca {
          id
          nome
        }
      }
    }
  }
`;

export const FIND_BY_ID_MODELO_VEICULO = gql`
  query findByIdModelo($id: UUID) {
    modelo: findByIdModelo(id: $id) {
      id
      nome
      marca {
        id
        nome
        value: id
        label: nome
      }
    }
  }
`;

export const FIND_BY_ID_MARCA_VEICULO = gql`
  query findByIdMarca($id: UUID) {
    marca: findByIdMarca(id: $id) {
      id
      nome
    }
  }
`;

export const FIND_ALL_MARCA_VEICULO = gql`
  query findAllMarca($pageable: PageableDTOInput, $searchDTO: SearchDTOInput) {
    marcas: findAllMarca(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        id
        nome
      }
    }
  }
`;

export const FIND_ALL_MOLDE_VEICULO = gql`
  query findAllMoldeVeiculo(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    moldes: findAllMoldeVeiculo(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        id
        marca {
          id
          nome
        }
        modelo {
          id
          nome
        }
        anoFabricacao
        anoModelo
        arquivoNome
        arquivo
        ativo
      }
    }
  }
`;

export const FIND_BY_ID_MOLDE_VEICULO = gql`
  query findByIdMoldeVeiculo($id: UUID) {
    molde: findByIdMoldeVeiculo(id: $id) {
      id
      marca {
        id
        nome
        value: id
        label: nome
      }
      modelo {
        id
        nome
        value: id
        label: nome
      }
      anoFabricacao
      anoModelo
      arquivoNome
      arquivo
      ativo
    }
  }
`;
export const FIND_ALL_OFICINAS = gql`
  query findAllOficinas(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    oficinas: findAllOficinas(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        id
        nome
        telefone
        cidade {
          nome
          uf
        }
      }
    }
  }
`;
export const FIND_BY_ID_OFICINA = gql`
  query findByIdOficina($id: UUID) {
    oficina: findByIdOficina(id: $id) {
      id
      ativo
      nome
      telefone
      cpfCnpj
      email
      bairro
      logradouro
      complemento
      cep
      numero
      contatoNome
      contatoEmail
      contatoTelefone
      cidade {
        id
        nomeEUf
        value: id
        label: nomeEUf
      }
    }
  }
`;
export const FIND_ENDERECO_BY_CEP = gql`
  query findEnderecoByCep($cep: String) {
    endereco: findEnderecoByCep(cep: $cep) {
      cep
      bairro
      complemento
      logradouro
      numero
      cidade {
        id
        value: id
        label: nomeEUf
      }
    }
  }
`;
export const GERAR_MAPA_DE_CALOR = gql`
  query GerarMapaDeCalor(
    $campanha: CampanhaInput
    $dataInicio: LocalDate
    $pageable: PageableDTOInput
    $dataFim: LocalDate
  ) {
    gerarMapaDeCalor: gerarMapaDeCalor(
      campanha: $campanha
      dataInicio: $dataInicio
      dataFim: $dataFim
      pageable: $pageable
    ) {
      last
      pageable {
        pageNumber
      }
      content {
        latitude
        longitude
        peso
      }
    }
  }
`;

export const FIND_CONFIGURACOES = gql`
  query findConfiguracoes($keys: [String]) {
    configuracoes(keys: $keys) {
      key
      value
    }
  }
`;

export const FIND_VALOR_ODOMETRO_ANTERIOR = gql`
  query findValorOdometroAnterior($id: UUID) {
    valor: findValorOdometroAnterior(id: $id)
  }
`;

export const FIND_VALOR_MOVIMENTACAO_MOTORISTA = gql`
  query valorMovimentacaoPeriodoMotorista($imagem: AnuncioImagemVeiculoInput) {
    valor: valorMovimentacaoPeriodoMotorista(imagem: $imagem)
  }
`;

export const FIND_VALOR_ODOMETRO_ANTERIOR_DATA = gql`
  query findValorOdometroAnteriorData(
    $anuncio: AnuncioInput
    $data: LocalDate
  ) {
    valor: findValorOdometroAnteriorData(anuncio: $anuncio, data: $data)
  }
`;

export const FIND_INSTALADOR_DEFAULT = gql`
  query findInstaladorPadraoCampanha($campanha: CampanhaInput) {
    instalador: findInstaladorPadraoCampanha(campanha: $campanha) {
      id
      value: id
      label: nome
    }
  }
`;

export const FIND_ALL_ESTADOS = gql`
  query findAllEstado($pageable: PageableDTOInput, $searchDTO: SearchDTOInput) {
    estados: findAllEstado(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        id
        nome
        uf
      }
    }
  }
`;

export const FIND_ESTADO_BY_ID = gql`
  query findByIdEstado($id: UUID) {
    estado: findByIdEstado(id: $id) {
      id
      nome
      uf
      kmPadrao
      raioPadrao
    }
  }
`;

export const FIND_CIDADE_BY_ID = gql`
  query findByIdCidade($id: UUID) {
    cidade: findByIdCidade(id: $id) {
      id
      nome
      codigoIBGE
      uf
      estado {
        id
        nome
        value: id
        label: nome
      }
      impactadosKm
      kmPadrao
      raioPadrao
      campanhasRealizadas
      kmMedio
      densidadeDemografica
      latitude
      longitude
      metricasAtualizadas
      populacao
      mediaCorridas
      pontosInteresse {
        id
        nome
        latitude
        longitude
        kmRaio
        tipo
      }
      palavrasChave {
        id
        palavraChave
        pontos {
          id
          nome
          latitude
          longitude
        }
      }
    }
  }
`;

export const FIND_ALL_PEDIDOS = gql`
  query findAllPedidos(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    pedidos: findAllPedidos(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        id
        titulo
        situacao
        quantidadeVeiculos
        dataCompra
        quantidadeMeses
        dataInicioVeiculacao
        diasPeriodo
        anunciante {
          nomeRazao
          nomeFantasia
        }
        comercial {
          nomeRazao
          nomeFantasia
        }
        cidade {
          nome
          uf
        }
        modeloAnuncio {
          nome
        }
      }
    }
  }
`;

export const FIND_ALL_ORCAMENTOS_APROVAR = gql`
  query findAllOrcamentosAprovar(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    pedidos: findAllOrcamentosAprovar(
      pageable: $pageable
      searchDTO: $searchDTO
    ) {
      last
      totalElements
      content {
        id
        titulo
        totalVeiculos
        dataInicio
        quantidadeMeses
        anunciante {
          nomeRazao
          nomeFantasia
        }
        comercial {
          nomeRazao
          nomeFantasia
        }
        itens {
          id
        }
        modeloAnuncio {
          nome
          diasPeriodo
        }
        proposta {
          id
          titulo
        }
      }
    }
  }
`;

export const CALCULATE_CAMPANHA = gql`
  query calculateCampanhaByAdmin(
    $campanha: CampanhaInput
    $parcelamento: ParcelamentoDTOInput
  ) {
    calculateCampanha: calculateCampanhaByAdmin(
      campanha: $campanha
      parcelamento: $parcelamento
    ) {
      valorPorVeiculo
      quantidadeVeiculos
      quantidadeMeses
      quantidadeKmMotoristaMes
      valor
      valorVeiculacao
      valorVeiculacaoMensal
      kmTotal
      movimentacaoFinanceiras {
        valor
        dataPrevisaoPgto
        parcelaTipo
        tipo
        formaPagamento
        dataEmitirNota
      }
    }
  }
`;

export const FIND_ALL_PROPOSTA = gql`
  query findAllProposta(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    propostas: findAllProposta(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        id
        titulo
        situacao
        dataCadastro
        dataAlteracao
        dataRetorno
        reagendamentos
        anunciante {
          nomeFantasia
          fotoPerfil
        }
        comercial {
          nomeFantasia
          nomeRazao
          nomeRazaoResponsavel
          role
        }
        comercialResponsavel {
          nomeFantasia
          nomeRazao
          role
        }
        orcamentos {
          id
          situacao
          quantidadeVeiculos
          reprovadoMotivo
        }
      }
    }
  }
`;

export const FIND_PROPOSTA_BY_ID = gql`
  query findByIdProposta($id: UUID) {
    proposta: findByIdProposta(id: $id) {
      id
      titulo
      situacao
      dataCadastro
      dataAlteracao
      dataRetorno
      anunciante {
        value: id
        label: nomeFantasia
        id
        nomeFantasia
        cadastroCompleto
      }
      comercial {
        value: id
        label: nomeRazao
        id
        nomeRazao
        role
      }
      comercialResponsavel {
        value: id
        label: nomeRazao
        id
        nomeRazao
        nomeFantasia
        role
      }
      observacao
      reprovadoMotivo
      eventos {
        id
        evento
        data
      }
      orcamentos {
        id
      }
    }
  }
`;

export const FIND_ALL_ORCAMENTOS_PROPOSTA = gql`
  query findAllOrcamentosDaProposta($proposta: PropostaInput) {
    orcamentos: findAllOrcamentosDaProposta(proposta: $proposta) {
      id
      titulo
      anunciante {
        value: id
        label: nomeFantasia
        id
        nomeFantasia
        cadastroCompleto
      }
      diasPeriodo
      dataCadastro
      dataAlteracao
      dataAprovadoAdmin
      quantidadeMeses
      comissaoAgencia
      valorTotalVeiculacaoMensal
      producaoPorVeiculo
      valorTotalCampanha
      situacao
      dataRetorno
      observacoes
      reprovadoMotivo
      valorReajuste
      dataInicio
      comissaoTerceiros
      parcelasVeiculacao
      dataVencimentoVeiculacao
      dataEmissaoNotaVeiculacao
      faturarProducao
      parcelasProducao
      dataVencimentoProducao
      dataEmissaoNotaProducao
      formaPagamentoVeiculacao
      faturarPor
      formaPagamentoProducao
      piVeiculacao {
        id
        nome
      }
      piProducao {
        id
        nome
      }
      itens {
        id
        cidade {
          id
          nome
          uf
          nomeEUf
          kmPadrao
          kmMedio
          impactadosKm
          mediaCorridas
          value: id
          label: nomeEUf
        }
        modeloAnuncio {
          id
          nome
          value: id
          label: nome
          valorInstaladorPorInstalacao
          valorVenda
          percentualImpactos
          diasPeriodo
          tabelaEspecial
          tabelaUm
          tabelaDois
          tabelaTres
          frente
          laterais
          lateraisRecorte
          traseira
          interno
          interativo
        }
        producaoPorVeiculo
        valorMensalPorVeiculo
        quantidadeVeiculos
        valorDescontoPorVeiculo
        valorTotalCidade
        motoristas
        mediaKmRodado
      }
      eventos {
        id
        data
        evento
        automatico
      }
    }
  }
`;

export const FIND_BY_ID_ORCAMENTO = gql`
  query findByIdOrcamento($id: UUID) {
    orcamento: findByIdOrcamento(id: $id) {
      id
      proposta {
        id
        titulo
      }
      anunciante {
        value: id
        label: nomeFantasia
        id
        nomeFantasia
        cadastroCompleto
        comercial {
          id
          nomeRazao
          nomeFantasia
        }
      }
      comercial {
        id
        nomeRazao
        nomeFantasia
        role
        comercialResponsavel {
          id
          nomeRazao
        }
      }
      diasPeriodo
      valorMensalPorVeiculo
      dataCadastro
      dataAlteracao
      quantidadeMeses
      comissaoAgencia
      valorTotalVeiculacaoMensal
      valorTotalProducao
      valorTotalCampanha
      situacao
      dataRetorno
      observacoes
      reprovadoMotivo
      totalVeiculos
      dataInicio
      comissaoTerceiros
      parcelasVeiculacao
      dataVencimentoVeiculacao
      dataEmissaoNotaVeiculacao
      faturarProducao
      parcelasProducao
      dataVencimentoProducao
      dataEmissaoNotaProducao
      formaPagamentoVeiculacao
      faturarPor
      formaPagamentoProducao
      valorReajuste
      piVeiculacao {
        id
        nome
      }
      piProducao {
        id
        nome
      }
      itens {
        id
        cidade {
          id
          nome
          uf
          nomeEUf
          kmPadrao
          kmMedio
          impactadosKm
          mediaCorridas
          value: id
          label: nomeEUf
        }
        modeloAnuncio {
          id
          nome
          value: id
          label: nome
          valorInstaladorPorInstalacao
          valorVenda
          percentualImpactos
          diasPeriodo
          tabelaEspecial
          tabelaUm
          tabelaDois
          tabelaTres
        }
        producaoPorVeiculo
        valorMensalPorVeiculo
        quantidadeVeiculos
        valorDescontoPorVeiculo
        valorTotalCidade
        motoristas
        mediaKmRodado
      }
    }
  }
`;

export const FIND_TOTAIS_PROPOSTAS = gql`
  query findTotaisPropostas($anuncianteId: UUID, $data: LocalDate) {
    totais: findTotaisPropostas(anuncianteId: $anuncianteId, data: $data) {
      situacao
      veiculosTotal
      valorTotal
      quantidadeOrcamentos
    }
  }
`;

export const FIND_AVALIACAO_MOTORISTA_BY_ANUNCIO = gql`
  query findAvaliacaoMotoristaByAnuncio($anuncio: AnuncioInput) {
    avaliacao: findAvaliacaoMotoristaByAnuncio(anuncio: $anuncio) {
      id
      nota
      notaKmRodado
      notaPessoal
      notaPreCampanha
      notaAdesivacao
      notaCampanha
      notaFinalizacao
      observacao
      observacaoPreCampanha
      observacaoAdesivacao
      observacaoCampanha
      observacaoFinalizacao
      pinturaAvariada
    }
  }
`;

export const FIND_ALL_AVALIACOES_DO_MOTORISTA = gql`
  query findAllAvaliacaoByMotorista(
    $motorista: MotoristaInput
    $pageable: PageableDTOInput
  ) {
    anuncios: findAllAvaliacaoByMotorista(
      motorista: $motorista
      pageable: $pageable
    ) {
      last
      totalElements
      content {
        id
        nota
        notaKmRodado
        notaPessoal
        pinturaAvariada
        anuncio {
          id
          campanhaTitulo
          quantidadeMeses
          kmTotalPorMes
          kmTotal
          kmRodado
          campanha {
            cidade {
              id
              nome
              uf
            }
          }
          veiculo {
            anoFabricacao
            anoModelo
            modelo {
              nome
              marca {
                nome
              }
            }
          }
        }
      }
    }
  }
`;

export const COUNT_ALL_ANUNCIOS_ATIVOS = gql`
  query countAllAnunciosAtivos {
    anuncios: countAllAnunciosAtivos
  }
`;

export const COUNT_ALL_CAMPANHAS_ATIVAS = gql`
  query countAllCampanhasAtivas {
    campanhas: countAllCampanhasAtivas
  }
`;

export const COUNT_MOTORISTAS_INDICADOS = gql`
  query countMotoristasAprovadosIndicados($motoristaId: UUID) {
    indicacoes: countMotoristasAprovadosIndicados(motoristaId: $motoristaId)
  }
`;

export const FIND_ALL_BANCOS = gql`
  query findAllBanco($pageable: PageableDTOInput, $searchDTO: SearchDTOInput) {
    bancos: findAllBanco(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        id
        nomeBanco
        codigoBanco
        codigoENome
      }
    }
  }
`;

export const FIND_ALL_MOTORISTA_PAGOS = gql`
  query findAllMotoristasPagosNoMes($searchDTO: SearchMovimentacoesDTOInput) {
    movimentacoes: findAllMotoristasPagosNoMes(searchDTO: $searchDTO) {
      id
      dataPgto
      valorPago
      motorista {
        nome
        cpf
      }
    }
  }
`;
export const FIND_ALL_CAMPANHA_TROCAS_MOTORISTA = gql`
  query findAllCampanhaTrocasMotorista(
    $pageable: PageableDTOInput
    $campanhaId: UUID
    $cidadeId: UUID
    $estadoId: UUID
  ) {
    findAllCampanhaTrocasMotorista(
      pageable: $pageable
      campanhaId: $campanhaId
      cidadeId: $cidadeId
      estadoId: $estadoId
    ) {
      content {
        anuncioId
        campanhaId
        dataTroca
        motoristaNome
        nomeCidade
        numeroTrocas
        tituloCampanha
        ufCidade
      }
    }
  }
`;
export const FIND_ALL_ANUNCIOS_TROCAS_MOTORISTA = gql`
  query findAllAnunciosTrocasMotorista($campanhaId: UUID) {
    findAllAnunciosTrocasMotorista(campanhaId: $campanhaId) {
      anuncioId
      campanhaId
      dataTroca
      motoristaNome
      nomeCidade
      numeroTrocas
      tituloCampanha
      ufCidade
    }
  }
`;
export const FIND_ALL_TROCAS_MOTORISTA_BY_ANUNCIO = gql`
  query findAllTrocasMotoristaByAnuncio($anuncio: AnuncioInput) {
    findAllTrocasMotoristaByAnuncio(anuncio: $anuncio) {
      anuncioSituacao
      dataTroca
      id
      motoristaDestino {
        nome
      }
      motoristaDestinoInstalado
      motoristaOrigem {
        nome
      }
      numeroTrocas
      anuncio {
        id
      }
      campanha {
        id
      }
    }
  }
`;
export const FIND_ALL_MOVIMENTACOES_EMITIR_NOTA = gql`
  query findAllMovimentacoesEmitirNota(
    $searchDTO: SearchMovimentacoesDTOInput
    $pageable: PageableDTOInput
  ) {
    movimentacoes: findAllMovimentacoesEmitirNota(
      searchDTO: $searchDTO
      pageable: $pageable
    ) {
      last
      totalElements
      content {
        id
        dataPgto
        valorPago
        valor
        dataEmitirNota
        notaEmitida
        campanha {
          id
          titulo
        }
        anunciante {
          id
          nomeFantasia
        }
        parcelaTipo
      }
    }
  }
`;

export const FIND_ALL_NOTIFICACOES = gql`
  query findAllNotificacoes(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    notificacoes: findAllNotificacoes(
      pageable: $pageable
      searchDTO: $searchDTO
    ) {
      last
      totalElements
      content {
        id
        titulo
        mensagem
      }
    }
  }
`;

export const FIND_BY_ID_NOTIFICACAO = gql`
  query findByIdNotificacao($id: UUID) {
    notificacao: findByIdNotificacao(id: $id) {
      id
      titulo
      mensagem
      dataCadastroInicio
      dataCadastroFim
      diasAnuncioParado
      diasSemana
      horarios
      campanha {
        id
        titulo
        value: id
        label: titulo
      }
      motorista {
        id
        nome
        value: id
        label: nome
      }
      situacao
      anuncioAtivo
      estado {
        id
        nome
        value: id
        label: nome
      }
      cidade {
        id
        nome
        value: id
        label: nomeEUf
      }
    }
  }
`;

export const FIND_ALL_CAMPANHAS_VENDIDAS = gql`
  query findAllMovimentacoesCampanhasVendidas(
    $searchDTO: SearchMovimentacoesDTOInput
    $pageable: PageableDTOInput
  ) {
    campanhas: findAllCampanhasVendidas(
      searchDTO: $searchDTO
      pageable: $pageable
    ) {
      last
      totalElements
      content {
        anuncianteId
        nomeFantasia
        titulo
        dataInicio
        dataFim
        cidades
        veiculos
        precoBruto
        valorVeiculo
        valorAgencia
        valorTerceiros
        precoLiquido
        valorVeiculoLiquido
      }
    }
  }
`;

export const BUSCAR_LUGARES_MAPS = gql`
  query buscarLugaresMaps(
    $location: String
    $radius: String
    $palavraChave: String
    $nextPageToken: String
    $key: String
  ) {
    lugares: buscarLugaresMaps(
      location: $location
      radius: $radius
      palavraChave: $palavraChave
      nextPageToken: $nextPageToken
      key: $key
    )
  }
`;

export const FIND_ALL_AGENCIA = gql`
  query findAllAgencia(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    agencias: findAllAgencia(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        id
        nomeRazao
        nomeFantasia
        cnpj
        ativo
        cidade {
          id
          nome
          uf
        }
        comercial {
          id
          nomeFantasia
          nomeRazao
          value: id
          label: nomeRazao
        }
        marketingTelefone
        marketingEmail
        marketingNome
      }
    }
  }
`;

export const FIND_BY_ID_AGENCIA = gql`
  query findByIdAgencia($id: UUID) {
    agencia: findByIdAgencia(id: $id) {
      id
      fotoPerfil
      nomeFantasia
      nomeRazao
      cnpj
      email
      inscricaoEstadual
      dataCadastro
      bairro
      logradouro
      complemento
      cep
      cidade {
        id
        nome
        uf
      }
      numero
      site
      marketingNome
      marketingEmail
      marketingTelefone
      financeiroNome
      financeiroEmail
      financeiroTelefone
      telefone
      comercial {
        id
        nomeRazao
        value: id
        label: nomeRazao
      }
      ativo
    }
  }
`;

export const FIND_ALL_COMERCIAL_RELATORIO = gql`
  query findAllComercial(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    comercial: findAllComercial(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        id
        nomeRazao
        nomeFantasia
        cpfCnpj
        telefone
        email
        nomeContato
        endereco {
          cidade {
            id
            nome
            uf
            nomeEUf
          }
        }
        agencias {
          id
          nomeRazao
          nomeFantasia
          cnpj
          marketingTelefone
          marketingNome
          marketingEmail
          cidade {
            id
            nome
            uf
            nomeEUf
          }
          anunciantes {
            id
            nomeRazao
            nomeFantasia
            cnpj
            marketingNome
            marketingEmail
            marketingTelefone
            segmentoAtuacao {
              id
              descricao
            }
            endereco {
              cidade {
                id
                nome
                uf
                nomeEUf
              }
            }
          }
        }
        anunciantes {
          id
          nomeRazao
          nomeFantasia
          cnpj
          marketingNome
          marketingEmail
          marketingTelefone
          segmentoAtuacao {
            id
            descricao
          }
          endereco {
            cidade {
              id
              nome
              uf
              nomeEUf
            }
          }
          agencia {
            comercial {
              id
            }
          }
        }
      }
    }
  }
`;
export const FIND_ALL_COMERCIAL_METAS = gql`
  query findAllComercialMetas(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    metas: findAllComercialMetas(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        id
        ano
        janeiro
        fevereiro
        marco
        abril
        maio
        junho
        julho
        agosto
        setembro
        outubro
        novembro
        dezembro
        comercial {
          licencaCidades {
            id
            cidade {
              id
              nomeEUf
              value: id
              label: nomeEUf
            }
          }
        }
        metaCidade {
          id
          cidade {
            id
            nomeEUf
            value: id
            label: nomeEUf
          }
        }
      }
    }
  }
`;
export const FIND_ALL_COMERCIAL_EXECUTIVO = gql`
  query findAllComercialExecutivo(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    findAllComercialExecutivo(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        id
        nome
      }
    }
  }
`;
export const CONSULTA_KM_RODADO_CIDADE = gql`
  query consultaKmRodadoPorCidade($cidadeId: UUID, $estadoId: UUID) {
    cidades: consultaKmRodadoPorCidade(
      cidadeId: $cidadeId
      estadoId: $estadoId
    ) {
      cidadeId
      cidadeNome
      campanhas
      veiculos
      kmTotal
    }
  }
`;

export const FIND_ALL_EVENTOS_AGENDA = gql`
  query findAllEventosAgenda(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    findAllEventosAgenda(pageable: $pageable, searchDTO: $searchDTO) {
      totalPages
      totalElements
      pageable {
        pageNumber
      }
      size
      content {
        id
        descricao
        observacao
        id
        titulo
        data
        situacao
        comercial {
          id
          nomeFantasia
          nomeRazao
        }
        orcamento {
          id
        }
        proposta {
          id
        }
      }
    }
  }
`;

export const FIND_ALL_SEGMENTOS = gql`
  query findAllSegmentoAtuacao(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    segmentos: findAllSegmentoAtuacao(
      pageable: $pageable
      searchDTO: $searchDTO
    ) {
      last
      totalElements
      content {
        id
        descricao
        anunciantes {
          id
        }
      }
    }
  }
`;

export const FIND_ALL_SEGMENTOS_RELATORIO = gql`
  query findAllSegmentoAtuacaoRelatorio(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    segmentos: findAllSegmentoAtuacaoRelatorio(
      pageable: $pageable
      searchDTO: $searchDTO
    ) {
      last
      totalElements
      content {
        id
        descricao
        anunciantes {
          id
          nomeRazao
          nomeFantasia
          cnpj
          marketingNome
          marketingEmail
          marketingTelefone
          endereco {
            cidade {
              id
              nome
              uf
              nomeEUf
            }
          }
        }
      }
    }
  }
`;

export const FIND_BY_ID_SEGMENTO = gql`
  query findByIdSegmentoAtuacao($id: UUID) {
    segmento: findByIdSegmentoAtuacao(id: $id) {
      id
      descricao
    }
  }
`;

export const COUNT_ALL_VEICULOS_ORCAMENTOS = gql`
  query countAllVeiculosOrcamentos {
    veiculos: countAllVeiculosOrcamentos
  }
`;

export const FIND_ALL_VEICULOS_ORCAMENTOS_CIDADE = gql`
  query findAllVeiculosPorCidadeESituacao(
    $situacao: OrcamentoSituacao
    $cidade: CidadeInput
    $pageable: PageableDTOInput
  ) {
    cidades: findAllVeiculosPorCidadeESituacao(
      situacao: $situacao
      cidade: $cidade
      pageable: $pageable
    ) {
      cidadeId
      cidadeNome
      veiculos
      motoristas
    }
  }
`;
export const FIND_ALL_ARQUIVOS = gql`
  query findAllArquivos(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    findAllArquivos(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        titulo
        id
        descricao
        dataAlteracao
      }
    }
  }
`;

export const FIND_BY_ID_ARQUIVO = gql`
  query findByIdArquivo($id: UUID) {
    arquivo: findByIdArquivo(id: $id) {
      id
      descricao
      titulo
      dataAlteracao
      urlArquivo
      arquivo {
        id
        nome
      }
    }
  }
`;

export const FIND_ALL_MOTORISTAS_HOME = gql`
  query findAllMotoristasMapaHome($filtro: String) {
    motoristas: findAllMotoristasMapaHome(filtro: $filtro) {
      id
      nome
      latitude
      longitude
      motoristas
    }
  }
`;

export const FIND_ALL_MOTORISTAS_TOTAIS_HOME = gql`
  query findTotaisMotoristasMapaHome {
    motoristas: findTotaisMotoristasMapaHome {
      motoristas
      motoristasEmCampanha
      motoristasDisponiveis
      motoristasPendentes
      motoristasIncompletos
      motoristasInativos
      motoristasReprovados
    }
  }
`;

export const FIND_ALL_IMPACTOS_TODOS_MOTORISTAS = gql`
  query impactosTotalMotoristasDaBase($search: SearchDTOInput) {
    impactos: impactosTotalMotoristasDaBase(search: $search) {
      modeloAnuncio {
        nome
      }
      impactos
    }
  }
`;

export const FIND_ALL_DURACAO_PERIODO = gql`
  query findAllDuracaoPeriodoModelo {
    dias: findAllDuracaoPeriodoModelo
  }
`;

export const PERMITE_EDITAR_CAMPANHA = gql`
  query permiteEdicaoCampanha($campanha: CampanhaInput) {
    permite: permiteEdicaoCampanha(campanha: $campanha)
  }
`;

export const FIND_ALL_CIDADES_CAMPANHA = gql`
  query findAllCidadesByCampanha(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    cidades: findAllCidadesByCampanha(
      searchDTO: $searchDTO
      pageable: $pageable
    ) {
      totalElements
      content {
        id
        titulo
        situacao
        quantidadeVeiculos
        cidade {
          id
          nomeEUf
          latitude
          longitude
        }
        modeloAnuncio {
          nome
        }
        anuncios {
          id
          situacao
        }
        campanha {
          id
        }
        anunciante {
          id
          nomeFantasia
        }
      }
    }
  }
`;

export const FIND_CAMPANHA_CIDADE_BY_ID = gql`
  query findCampanhaCidadeById($id: UUID) {
    campanhaCidade: findCampanhaCidadeById(id: $id) {
      id
      situacao
      quantidadeVeiculos
      titulo
      campanha {
        id
      }
      cidade {
        id
        nome
        uf
        nomeEUf
        value: id
        label: nomeEUf
      }
      modeloAnuncio {
        id
        nome
      }
      anuncios {
        id
      }
    }
  }
`;

export const CONSULTA_RESULTADO_CAMPANHAS = gql`
  query relatorioCampanhaResultados($searchDTO: SearchDTOInput) {
    resultado: relatorioCampanhaResultados(searchDTO: $searchDTO) {
      campanhas
      cidades
      impactos
      passageiros
      kmRodado
    }
  }
`;

export const FIND_ALL_CAMPANHA_FALTANDO_MOTORISTAS = gql`
  query findAllCampanhasMotoristasPendentes {
    campanhas: findAllCampanhasMotoristasPendentes {
      id
      numero
      titulo
      situacao
      quantidadeVeiculos
      quantidadeVeiculosNaCampanha
      quantidadeMeses
      dataCompra
      dataInicioVeiculacao
      diasPeriodo
      anunciante {
        id
        nomeRazao
        nomeFantasia
      }
      comercial {
        nomeRazao
        nomeFantasia
        nomeRazaoResponsavel
        endereco {
          cidade {
            nome
            uf
            latitude
            longitude
          }
        }
      }
      cidades {
        modeloAnuncio {
          nome
        }
        cidade {
          nome
          uf
          latitude
          longitude
        }
      }
    }
  }
`;
